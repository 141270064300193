<template>
  <div class="app-container" v-loading="loading">
    <div>
      <div style="display:flex;align-items: center;margin-right: 10px;margin-left: 10px">
        <div>
          <i class="ki-outline" style="font-size: 16px" :class="$route.meta.icon"></i>
        </div>
        <el-breadcrumb separator-class="el-icon-arrow-right"
                       style="padding: 10px 5px;font-size: 12px;display: flex;align-items: center">
          <el-breadcrumb-item>{{ this.$route.matched[0].meta.title }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.$route.matched[1].meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Layout-->
        <div class="d-flex flex-column flex-lg-row">
          <!--begin::Sidebar-->
          <div class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
            <!--begin::Contacts-->
            <div class="card card-flush" style="height: 80vh">
              <!--begin::Card header-->
              <div class="card-header pt-7" id="kt_chat_contacts_header">
                <!--begin::Form-->
                <form class="w-100 position-relative" autocomplete="off">
                  <!--begin::Icon-->
                  <i class="ki-outline ki-magnifier fs-3 text-gray-500 position-absolute top-50 ms-5 translate-middle-y"></i>
                  <!--end::Icon-->
                  <!--begin::Input-->
                  <input type="text" class="form-control form-control-solid px-13" name="search" value=""
                         placeholder="搜索会话" v-model="search"/>
                  <!--end::Input-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body pt-5" id="kt_chat_contacts_body"
                   style="min-height: 72vh;max-height: 72vh;overflow: hidden;overflow-y: auto;cursor: pointer">
                <!--begin::List-->
                <div class="scroll-y me-n5 pe-5 h-200px h-lg-auto"

                     v-for="(item,index) in conversationList" :key="index"
                     data-kt-scroll="true"
                     data-kt-scroll-activate="{default: false, lg: true}"
                     data-kt-scroll-max-height="auto"
                     data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_toolbar, #kt_app_toolbar, #kt_footer, #kt_app_footer, #kt_chat_contacts_header"
                     data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_contacts_body"
                     data-kt-scroll-offset="5px"
                     @click="changeMessageUserId(item.userProfile.userID,item.userProfile.nick)">
                  <!--begin::User-->
                  <div class="d-flex flex-stack py-4">
                    <!--begin::Details-->
                    <div class="d-flex align-items-center">
                      <!--begin::Avatar-->
                      <div class="symbol symbol-45px symbol-circle">
                        <img alt="Pic" :src="item.userProfile.avatar"/>
                      </div>
                      <!--end::Avatar-->
                      <!--begin::Details-->
                      <div class="ms-5">
                        <div
                            class="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">{{ item.userProfile.nick }}
                        </div>
                        <div class="fw-semibold text-muted">{{ item.lastMessage.messageForShow }}</div>
                      </div>
                      <!--end::Details-->
                    </div>
                    <!--end::Details-->
                    <!--begin::Lat seen-->
                    <div class="d-flex flex-column align-items-end ms-2">
                      <span class="text-muted fs-7 mb-1">{{ timeAgo(item.lastMessage.lastTime) }}</span>
                      <span class="badge badge-sm badge-circle badge-light-danger"
                            v-if="item.unreadCount > 0">{{ item.unreadCount }}</span>
                    </div>
                    <!--end::Lat seen-->
                  </div>
                  <!--end::User-->
                  <!--begin::Separator-->
                  <div class="separator separator-dashed d-none"></div>
                  <!--end::Separator-->
                </div>
                <!--end::List-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Contacts-->
          </div>
          <!--end::Sidebar-->
          <!--begin::Content-->
          <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10" v-if="messageUserId">
            <!--begin::Messenger-->
            <div class="card" id="kt_chat_messenger" style="position: relative;display: flex;flex-direction: column;">
              <!--begin::Card header-->
              <div class="card-header" id="kt_chat_messenger_header" style="">
                <!--begin::Title-->
                <div class="card-title">
                  <!--begin::User-->
                  <div class="d-flex justify-content-center flex-column me-3">
                    <a href="#"
                       class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">{{ messageUserName }}</a>
                  </div>
                  <!--end::User-->
                </div>
                <!--end::Title-->
              </div>
              <!--end::Card header-->
              <!--begin::Card body-->
              <div class="card-body" ref="messageList" id="kt_chat_messenger_body"
                   style="max-height: 54vh;min-height: 54vh;overflow: hidden;overflow-y: auto;">
                <!--begin::Messages-->
                <div class="scroll-y me-n5 pe-5 h-300px h-lg-auto"
                     v-for="(item,index) in messageList" :key="index"
                     data-kt-element="messages"
                     data-kt-scroll="true"
                     data-kt-scroll-activate="{default: false, lg: true}"
                     data-kt-scroll-max-height="auto"
                     data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
                     data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body"
                     data-kt-scroll-offset="5px">
                  <!--begin::Message(in)-->
                  <div class="d-flex justify-content-start mb-10" v-if="item.flow === 'in'">
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column align-items-start">
                      <!--begin::User-->
                      <div class="d-flex align-items-center mb-2">
                        <!--begin::Avatar-->
                        <div class="symbol symbol-35px symbol-circle">
                          <img alt="Pic" :src="item.avatar"/>
                        </div>
                        <!--end::Avatar-->
                        <!--begin::Details-->
                        <div class="ms-3">
                          <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{{ item.nick }}</a>
                          <span class="text-muted fs-7 mb-1">{{ timeAgo(item.time) }}</span>
                        </div>
                        <!--end::Details-->
                      </div>
                      <!--end::User-->
                      <!--begin::Text-->
                      <div class="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                           data-kt-element="message-text">{{ item.payload.text }}
                      </div>
                      <!--end::Text-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Message(in)-->
                  <!--begin::Message(out)-->
                  <div class="d-flex justify-content-end mb-10" v-if="item.flow === 'out'">
                    <!--begin::Wrapper-->
                    <div class="d-flex flex-column align-items-end">
                      <!--begin::User-->
                      <div class="d-flex align-items-center mb-2">
                        <!--begin::Details-->
                        <div class="me-3">
                          <span class="text-muted fs-7 mb-1">{{ timeAgo(item.time) }}</span>
                          <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">你</a>
                        </div>
                        <!--end::Details-->
                        <!--begin::Avatar-->
                        <div class="symbol symbol-35px symbol-circle">
                          <img alt="Pic" :src="item.avatar"/>
                        </div>
                        <!--end::Avatar-->
                      </div>
                      <!--end::User-->
                      <!--begin::Text-->
                      <div class="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end"
                           data-kt-element="message-text">{{ item.payload.text }}
                      </div>
                      <!--end::Text-->
                    </div>
                    <!--end::Wrapper-->
                  </div>
                  <!--end::Message(out)-->
                </div>
                <!--end::Messages-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div class="card-footer pt-4" id="kt_chat_messenger_footer">
                <!--begin::Input-->
                <textarea v-model="sendText" class="form-control form-control-flush mb-3" rows="2"
                          data-kt-element="input"
                          placeholder="输入消息"></textarea>
                <!--end::Input-->
                <!--begin:Toolbar-->
                <div class="d-flex flex-stack">
                  <!--begin::Actions-->
                  <div class="d-flex align-items-center me-2">
                    <!--                    <button class="btn btn-sm btn-icon btn-active-light-primary me-1" type="button"
                                                data-bs-toggle="tooltip" title="Coming soon">
                                          <i class="ki-outline ki-exit-up fs-3"></i>
                                        </button>-->
                  </div>
                  <!--end::Actions-->
                  <!--begin::Send-->
                  <button class="btn btn-primary" type="button" data-kt-element="send" @click="sendMessage">发送
                  </button>
                  <!--end::Send-->
                </div>
                <!--end::Toolbar-->
              </div>
              <!--end::Card footer-->
            </div>
            <!--end::Messenger-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Layout-->
      </div>
      <!--end::Content container-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script>
import {getAllConversationList, setTextMessage, getMessageList, imLogin, imOutLogin} from "@/utils/timUtils";
import TIM from "tim-js-sdk";
import {mapActions, mapState} from "vuex";

export default {
  name: "PrivateLetter",
  data() {
    return {
      search: "",
      loadMessage: false,
      sendText: "",
      messageUserId: "",
      messageUserName: "",
      userId: "",
      peoList: [],
      messageList: [],
      timer: null,
      loading: false,
    }
  },
  filters: {},
  computed: {
    ...mapState("user", ["userInfo"]),
    conversationList() {
      return this.peoList.filter(item => {
        return item.userProfile.nick.indexOf(this.search) !== -1
      })
    }
  },
  mounted() {

  },
  methods: {
    //发送消息
    async sendMessage() {
      this.loadMessage = true
      const res = await setTextMessage(this.sendText, this.messageUserId)
      this.messageList.push(res)
      this.sendText = ""
      let container = document.getElementById("kt_chat_messenger_body");
      setTimeout(() => {
        container.scrollTo(0, container.scrollHeight)
      }, 4)
      this.loadMessage = false
    },
    //获取聊天记录
    async getMessage(userId, nextId) {
      this.loadMessage = true
      const res = await getMessageList(userId, nextId)
      this.messageList = res.messageList
      let container = document.getElementById("kt_chat_messenger_body");
      setTimeout(() => {
        container.scrollTo(0, container.scrollHeight)
      }, 4)
      this.loadMessage = false
    },
    //收到的新消息
    onMessageReceived(event) {
      this.messageList = this.messageList.concat(event.data)
    },
    changeMessageUserId(userId, userName) {
      this.messageUserId = userId
      this.messageUserName = userName
      this.getMessage(userId)
    },
    timeAgo(dateTime) {
      const now = new Date();
      const date = new Date(dateTime * 1000);
      const seconds = Math.floor((now - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1)
        return interval + " 年前";
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1)
        return interval + "个月前";
      interval = Math.floor(seconds / 86400);
      if (interval >= 1)
        return interval + "天前";
      interval = Math.floor(seconds / 3600);
      if (interval >= 1) return interval + " 小时前";
      interval = Math.floor(seconds / 60);
      if (interval >= 1)
        return interval + " 分钟前";
      return "刚刚";
    },

  },
  created() {

    this.loading = true
    setTimeout(() => {

      this.userId = this.userInfo.bindAppUuid
      this.userSig = this.userInfo.userSig

      if (this.$route.query.toUuid) {
        this.messageUserId = this.$route.query.toUuid
        this.messageUserName = this.$route.query.toName
      }
      imLogin(this.userId, this.userSig)
      this.$tim.on(TIM.EVENT.SDK_READY, () => {
        getAllConversationList().then(res => {
          this.peoList = res.conversationList
          this.$tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onMessageReceived);
        })
        if (this.messageUserId){
          this.getMessage(this.messageUserId)
        }
      })
    }, 1000)

    this.loading = false
  },
  destroyed() {
  }
}
</script>

<style scoped>

</style>