<template>
  <div>
    <el-dialog title="文章评论列表"
               :visible.sync="articleListDialogShow"
    >

      <el-row style="margin-top: 10px">

        <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px" v-if="total!=0">
          <div class="tab-content">
            <div
                class="tab-pane fade show active">
              <div class="d-flex flex-column gap-7 gap-lg-10">
                <div class="card card-flush"
                     style="border-radius:15px !important; ">
                  <div class="card-body pt-0">
                    <div class="row" style="padding-left: 0;padding-right: 0;padding-bottom: 0">
                      <div class="table-responsive" style="display:flex;min-height: 54vh">
                        <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                          <thead>
                          <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th rowspan="1" colspan="1"
                                style="width: 200px;margin-left: 20px">
                              文章标题
                            </th>
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              手机号
                            </th>
                            <th tabindex="0" rowspan="1" colspan="1" class="text-center" style="width:200px;">
                              评论内容
                            </th>

                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              评论时间
                            </th>

                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              回复内容
                            </th>

                            <th rowspan="1" colspan="1" class="text-center sorting_disabled" style="width:100px;">
                              操作
                            </th>
                          </tr>
                          </thead>
                          <tbody class="fw-semibold text-gray-600">
                          <tr class="old" v-for="(item,index) in rows" :key="index">
                            <td>
                              <div class="d-flex align-items-center" style="margin-left: 20px">
                                <a href="javascript:void(0);" class="symbol symbol-50px">
                                  <img
                                      :src="item.imgUrl"
                                      class="symbol-label lozad">
                                </a>
                                <div class="ms-5">
                                  <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5">
                                    {{item.articleName}}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td class="text-center pe-0">
                              {{ item.userTel }}
                            </td>
                            <td class="text-center pe-0">
                              <span class="text-clamp-two-lines" :title="item.content">{{item.content}}</span>
                            </td>
                            <td class="text-center pe-0">
                              {{ item.createTime }}
                            </td>
                            <td class="text-center pe-0">
                              <span class="text-clamp-two-lines" :title="item.replyContent">{{!item.replyContent?'未回复':item.replyContent}}</span>
                            </td>
                            <td class="text-center">
<!--                              <el-link type="primary" @click="viewThis(item)">查看</el-link>-->
                              <el-link v-if="!item.replyContent" type="success" @click="replyThis(item)" style="margin-left: 10px">回复</el-link>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style="display: flex;justify-content: center">
                      <el-pagination style="display:flex;justify-content:flex-end" @size-change="handleSizeChange"
                                     @current-change="handleCurrentChange" :current-page="params.current" :page-sizes="[10,20,30]"
                                     :page-size="params.size" layout="total, sizes, prev, pager, next, jumper" :total="total"
                                     background>
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
        <el-empty v-else description="暂无评论" style="min-height: 40vh"></el-empty>
    </el-row>
    </el-dialog>
    <el-dialog
      :title="modeType===1?'评价详情':'回复'"
      :visible.sync="viewDialogShow"
    >
      <div style="display: flex; flex-direction: column; height: 100%;">
        <el-card>
            <el-row>客户评论</el-row>
            <el-row>
              <el-input type="textarea" v-model="detailInfo.content" readonly></el-input>
            </el-row>
            <el-row style="margin-top: 10px">
              <div v-for="(item,index) in detailInfo.fileDomainList">
                <el-image :src="item.fullUrl" style="width: 100px;height: 100px;margin-right: 10px"></el-image>
              </div>
            </el-row>
        </el-card>

        <el-card style="margin-top: 10px">
          <el-row>商家回复</el-row>
          <el-row>
            <el-input type="textarea" v-model="detailInfo.replyContent" :readonly="modeType===1"></el-input>
          </el-row>
          <el-row type="flex" justify="end" style="margin-top: 10px">
            <el-button size="mini" type="primary" @click="replySubmit" v-if="modeType===2">提交</el-button>
          </el-row>

        </el-card>

      </div>
    </el-dialog>

  </div>
</template>
<script>

import {mixins} from "@/views/Content/article/evaluation/mixins";
import Paginations from "@/components/Paginations/index.vue";
export default {
  components: {Paginations},
  mixins: [mixins]
}
</script>

<style scoped>

.text-clamp-two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
