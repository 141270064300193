
const state = {
    tim:null
}

const mutations = {

}
const actions = {

}
const getters = {

}

export default {
    state,
    mutations,
    actions,
    getters
}