import service from "@/utils/request"


export function addArticleApi(data){
    return service({
        url: "/article/addArticle",
        method: "post",
        data
    })
}

export function updateArticleApi(data){
    return service({
        url: "/article/updateArticle",
        method: "post",
        data
    })
}

export function queryArticlePageApi(params){
    return service({
        url: "/article/queryArticlePage",
        method: "get",
        params
    })
}

export function getArticleInfoApi(params){
    return service({
        url: "/article/getArticleInfo",
        method: "get",
        params
    })
}

export function deleteArticleApi(data){
    return service({
        url: "/article/deleteArticle",
        method: "post",
        data
    })
}

export function queryAllArticleApi(params){
    return service({
        url: "/article/queryArticleBaseList",
        method: "get",
        params
    })
}