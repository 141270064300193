import TIM from 'tim-js-sdk';
import * as utils from '../utils/timUtils'
import Vue from 'vue'
// 空实例对象
let tim = {}
// 创建tim初始化方法
tim.create = (timOptions) => {
    //创建实例
    tim.txIm = TIM.create({ SDKAppID: timOptions.SDKAppID })
    //输出等级
    tim.txIm.setLogLevel(timOptions.logLevel)
    // TIM websocket监听事件常量
    tim.txIm.EVENT = TIM.EVENT
    // TIM 会话类型常量
    tim.txIm.TYPES = TIM.TYPES
    // 集成工具包
    tim.Utils = utils
}
// 创建tim插件注册方法
tim.registerPlugin = (options)=>{
    tim.txIm.registerPlugin(options);
}
export default tim;
