<template>
  <div style="min-height: 85vh">
    <TitleCard
        title="新建上门安装模板"
        style="margin: 10px 10px; max-width: 99%; border-radius: 15px"
    >
      <template v-slot:subTitle>
        <ul
            class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
            style="display: flex"
        >
          <li
              class="breadcrumb-item text-muted"
              rel="stylesheet"
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
              style="font-family: 'Roboto', sans-serif"
          >
            Add Install Template
          </li>

          <div
              class="align-items-center py-3"
              style="position: absolute; right: 0; padding: 0 30px"
          >
            <a
                href="javascript:void(0)"
                class="btn btn-sm btn-primary mx-2"
                @click="$router.replace('/goods/install')"
            >返回到上门安装模板</a
            >
          </div>
        </ul>
      </template>
    </TitleCard>
    <el-form :model="installInfo" label-width="150px" class="position demo-dynamic bg-white">
      <el-form-item prop="templateName" label="上门安装模板名称:" class="pt-7 position">
        <el-input v-model.trim="installInfo.title" class="w-300px"></el-input>
        <label class="ms-7"> 模板状态: </label>
        <el-switch
            class="ms-5"
            v-model="installInfo.isStatus"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="启用"
            inactive-text="停用"
            :active-value="1"
            :inactive-value="2">
        </el-switch>
      </el-form-item>
      <label style="margin-left: 550px !important;"> 收费地区 </label>
      <label style="margin-left: 315px !important;"> 收费标准(元) </label>
      <el-form-item class="pt-2 position" :label="`地区及其收费规则${index+1}:`" v-for="(item, index) in installInfo.addressIdentifications" :key="index" :rules="{required: true}">
        <el-input class="w-50 me-5" style="cursor: pointer " v-model="item.clothesAll" :rules="{required: true, message: '区域不能为空', trigger: 'blur' }"
                  @focus="clickRechargeCreate(index,item.clothesId)" :readonly="true"></el-input>
        <el-input class="w-100px" v-model="item.money"></el-input>
        <el-button class="ms-5 " type="danger" @click="removeItem(index)" v-if="index !== 0">删除</el-button>
      </el-form-item>
      <div style="display: flex;justify-content: center;">
        <div>
          <el-button class="ms-5 mb-5" type="primary" @click="addItem">新增选项</el-button>
          <el-button class="ms-5 mb-5" type="primary" @click="submitInstall">提交模板</el-button>
        </div>
      </div>
    </el-form>
    <ModalDialog ref="AddRegion" title="地区选择" width="35%" :closeBtn="true">
      <el-tree
          style="height: 500px !important;overflow-y: scroll"
          :data="treeData"
          show-checkbox
          :default-expanded-keys="[1]"
          accordion
          node-key="id"
          ref="tree"
      >
      </el-tree>
      <el-divider></el-divider>
      <!-- 只能让某一个子元素 居中 -->
      <div style="display: flex;justify-content: center">
        <div>
          <el-button class="me-auto ms-20" @click="clickRechargeClose(0)">取消</el-button>
          <el-button class="me-auto ms-20" type="primary" @click="clickRechargeClose(1)">确定</el-button>
        </div>
      </div>
    </ModalDialog>

  </div>
</template>
<script>

import {getJdRegionDetail} from '@/api/jdRegionApi'
import {addInstallTemplate} from '@/api/install'
import RechargeCreate from "@/views/Finance/components/RechargeRecord/RechargeCreate.vue";

export default {
  components: {RechargeCreate},
  props: {},
  data() {
    return {
      index: 0,
      installInfo: {
        title: "",
        isStatus: 2,
        addressIdentifications: [{
          provinceAll: "",
          provinceId: "",
          clothesAll: "",
          clothesId: "",
          money: ""
        }],
      },
      treeData: [{
        id: 1,
        label: "全部",
        children: []
      }],
    };
  },
  methods: {
    removeItem(index) {
      this.installInfo.addressIdentifications.splice(index, 1);
    },
    addItem() {
      this.installInfo.addressIdentifications.push({
        provinceAll: "",
        provinceId: "",
        clothesAll: "",
        clothesId: "",
        firstOne: "",
        firstMoney: "",
        second: "",
        secondMoney: "",
        satisfyMoney: "",
        money: ""
      });
    },
    // 树状数据 选择后 回显到页面上
    handleNodeClick(data) {
      data = this.$refs.tree.getCheckedNodes();
      let children = data
      let str = []
      let arrNum = []
      let arr2 = []
      let arr3 = []
      children.forEach(item => {
        if (item.label === "全部"){
          return;
        }
        if (!item.children) {
          return
        }
        arr2.push(item.id)
        arr3.push(item.label)
        let arr = []
        let arr1 = []
        item.children.forEach(i => {
          arr.push(i.label)
          arr1.push(i.id)
        });
        arrNum.push(arr1.join(","))
        str.push(`${item.label}(${arr.join(',')})`)
      });
      this.installInfo.addressIdentifications[this.index].clothesId = arrNum.join("#") + "#";
      this.installInfo.addressIdentifications[this.index].clothesAll = str.join('');
      this.installInfo.addressIdentifications[this.index].provinceId = arr2.join(',');
      this.installInfo.addressIdentifications[this.index].provinceAll = arr3.join(',');

    },
    // 获取上门安装模板信息
    async getInstallInfo(id) {
      let res = await (id);
      this.installInfo = res.data;
    },
    // 将地址数据回显到 弹窗的树状图
    async getJdRegion() {
      let res = await getJdRegionDetail();
      let obj = [{
        id: 1,
        label: "全部",
        children: []
      }]
      res.data.forEach(item => {
        obj[0].children.push({
          id: item.provinceId,
          label: item.provinceName,
          children: []
        });
        if (item.cityVo) {
          let a = obj[0].children.length - 1;
          item.cityVo.forEach(i => {
            obj[0]['children'][a].children.push({
              id: i.cityId,
              label: i.cityName
            });
          });
        }
        this.treeData = obj;
      });
    },
    // 弹窗
    clickRechargeCreate(index, ids) {
      if (ids) {
        ids = ids.replace(/#/g, ",").split(",")
        this.$refs.tree.setCheckedKeys(ids);
      } else {
        this.$refs.tree.setCheckedKeys([])
      }
      this.index = index;
      this.$refs.AddRegion.open();
    },
    // 弹窗关闭 选择数据
    clickRechargeClose(type) {
      if (!type) {
        this.$refs.tree.setCheckedKeys([])
        this.installInfo.addressIdentifications.clothesAll = "";
      } else {
        this.handleNodeClick()
      }
      this.$refs.AddRegion.close();
    },
    // 提交模板
    async submitInstall() {
      if (!this.installInfo.title) {
        this.$message({
          message: "请填写模板名称",
          type: "warning",
        });
        return;
      }
      if (!this.installInfo.isStatus) {
        this.$message({
          message: "请选择模板状态",
          type: "warning",
        });
        return;
      }
      const isAllEmpty = this.installInfo.addressIdentifications.every(identification => {
        return Object.values(identification).some(value => value === '');
      });
      if (isAllEmpty) {
        this.$message({
          message: "请选择收费地区和收费规则",
          type: "warning",
        });
        return;
      }
      let res = await addInstallTemplate(this.installInfo);
      this.$router.push("/goods/install");
    }
  },
  created() {
    // 有id为编辑
    if (this.$route.params.id) {
      let id = this.$route.params.id;
      this.getInstallInfo(id);
    }
    this.getJdRegion();
  },
  mounted() {
  },
};
</script>
<style scoped>
.position {
  width: 85% !important;
  margin-left: 120px;
}
</style>
