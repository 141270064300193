import service from "@/utils/request"

export function getGoodsDetailApi(params) {
    return service({
        url: "/sjGoods/getGoodsDetail",
        method: "get",
        params
    })
}


export function queryGoodsSimpleInfoPageApi(params) {
    return service({
        url: "/sjGoods/querySelfGoodsSimpleInfoPage",
        method: "get",
        params
    })
}
