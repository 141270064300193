
import axios from "axios";
import {addArticleApi} from "@/api/lxh/applet/article";
import {getPlaceSuggestionApi} from "@/api/qq-map";
import {getStoreListApi} from "@/api/lxh/store";
import {queryGoodsSimpleInfoPageApi} from "@/api/lxh/goodsManage/sjGoods";
export const mixins = {
    data(){
        return{
            selectedValue: [],
            props: {
            },
            storeInfoRules:{

            },
            options: [],
            value: '',
            dialog:false,
            fileIndex:'',
            useType:'',
            articleInfo:{
                title:"",
                comboId:"",
                storeId:"",
                content:"",
                longitude:"",
                visibilityLevel:'1',
                latitude:"",
                addressDetail:"",
                articleFileList:[
                ]
            },
            visibilityLevelList:[{
                desc: '全部可见',
                value: '1'
            }, {
                desc: '仅自己可见',
                value: '2'
            }],
            timePickerValue:[new Date(),new Date()],
            storeList:[],
            categoryList:[],
            restaurants: [],
            state: '',
            timeout:  null,
            mapLocation:{
                lat:"",
                lng:""
            },
            map: null,
            marker: null,
            goodsList:[],
            addLoading:false,
        }
    },
    methods:{
        async getGoodsList(size=10){
            let {data}=await queryGoodsSimpleInfoPageApi({
                current: 1,
                size:999
            })
            this.goodsList = data.rows
            // if (data.rows){
            //     await this.getComboList(total);
            // }
        },
        async getStoreList(id) {
            let param = {
                current:1,
                size:999
            }
            let {data} = await getStoreListApi(param)
            this.storeList = data.rows;
            if (data.rows.length) {
                if (id) {
                    // this.storeChange(id)
                } else {
                    // this.storeChange(this.chooseStoreId)
                }
            }
        },
        getDefaultStore(){
            for(let i = 0; i < this.storeList.length;i++){
                let currentStore = this.storeList[i]
                if (!currentStore.isSubStore) {
                    this.articleInfo.storeId = currentStore.id
                    this.articleInfo.addressDetail = currentStore.chooseAddress
                    this.articleInfo.latitude = currentStore.storeLat
                    this.articleInfo.longitude = currentStore.storeLng
                }
            }
        },
        chooseThisStore(){
            if(!this.articleInfo.storeId){
                this.articleInfo.addressDetail = ''
                this.articleInfo.latitude=''
                this.articleInfo.longitude=''
                return
            }
            for(let i = 0; i < this.storeList.length;i++){
                let currentStore = this.storeList[i]
                if (currentStore.id === this.articleInfo.storeId) {
                    this.articleInfo.addressDetail = currentStore.chooseAddress
                    this.articleInfo.latitude = currentStore.storeLat
                    this.articleInfo.longitude = currentStore.storeLng
                    this.updateMarker()
                    break;
                }
            }
        },
        async uploadFile(e){
            this.fileObj=e.file
            this.storeLogoFile=e.file
            this.storeInfo.storeLogo=URL.createObjectURL(e.file)
        },

        initDialog(){
            this.dialog = true
            this.getStoreList()
            this.$nextTick(()=>{
                this.initMap()
            })
            this.getGoodsList()
        },
        handleCloseDialog(){
            const initialData = this.$options.data.call(this);
            Object.keys(this.$data).forEach(key => {
                this.$set(this.$data, key, initialData[key]);
            });
            document.getElementById('addArticlecontainerMaps').innerHTML = '';
        },
        /**
         * 初始化地图
         */
        initMap() {
            //定义地图中心点坐标
            let center = new TMap.LatLng(this.articleInfo.latitude, this.articleInfo.longitude)
            //定义map变量，调用 TMap.Map() 构造函数创建地图
            this.map = new TMap.Map(document.getElementById('addArticlecontainerMaps'), {
                center: center,//设置地图中心点坐标
                zoom:18
            });

            // 添加初始标记
            this.marker = new TMap.MultiMarker({
                map: this.map,
                styles: {
                    marker: new TMap.MarkerStyle({
                        "width": 25,
                        "height": 35,
                        "anchor": { x: 16, y: 32 }
                    })
                },
                geometries: [{
                    id: "marker",
                    styleId: "marker",
                    position: new TMap.LatLng(this.articleInfo.latitude, this.articleInfo.longitude)
                }]
            });

        },
        handleSelect(e){
            this.mapLocation={
                lat:e.location.lat,
                lng:e.location.lng
            }
            this.articleInfo.addressDetail = e.address
            this.articleInfo.latitude=this.mapLocation.lat
            this.articleInfo.longitude=this.mapLocation.lng
            this.updateMarker()
        },
        /**
         * 搜索
         */
        querySearchAsync(queryString, cb) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(async () => {
                let {data} = await getPlaceSuggestionApi({region: "", keyword: queryString})
                let arr=JSON.parse(data);
                cb(arr.data);
            }, 100);
        },
        /**
         * 更新标记点位置
         */
        updateMarker() {
            this.marker.setGeometries([{
                id: "marker",
                styleId: "marker",
                position: new TMap.LatLng(this.articleInfo.latitude, this.articleInfo.longitude)
            }]);
            // 更新地图中心位置
            this.map.setCenter(new TMap.LatLng(this.articleInfo.latitude, this.articleInfo.longitude));
        },
        openMaterial(type, index) {
            this.fileIndex = index;
            this.useType = type;
            switch (type) {
                case 'headImg':
                    if (this.fileIndex >= 5) {
                        this.$message({
                            message: '套餐头图不得超过5张！',
                            type: 'error'
                        });
                        break;
                    }
                    this.$refs.MaterialComponent.init(5-this.articleInfo.articleFileList.length, 'img')
                    break;

            }
        },
        useImg(item) {
            if (item.length === 0) {
                return;
            }
            let obj = {
                url: item[0].url,
                fullUrl: item[0].fullUrl
            }
            switch (this.useType) {
                case 'headImg':
                    // this.articleInfo.articleFileList[this.fileIndex] = obj
                    item.forEach(r=>{
                        let obj = {
                            url: r.url,
                            fullUrl: r.fullUrl
                        }
                        obj.type = 1
                        this.articleInfo.articleFileList.push(obj)
                    })
                    //this.headImgFileFullUrlList = JSON.parse(JSON.stringify(this.headImgFileFullUrlList))
                    break;
            }
        },
        removeThisImg(index){
            this.$delete(this.articleInfo.articleFileList, index);
        },

        checkParam() {
            let result = {}
            result.code = 201
            let articleInfo = this.articleInfo;
            let title = articleInfo.title.trim()
            if (!title) {
                result.msg = '请输入文章名称'
                return result;
            }
            if(title.length>=100){
                result.msg = '文章名称长度不能超过100个字符'
                return result;
            }
            let content = articleInfo.content.trim()

            if (!content) {
                result.msg = '请输入文章内容'
                return result;
            }
            if(content.length>=2000){
                result.msg = '文章内容长度不能超过2000个字符'
                return result;
            }
            let addressDetail = articleInfo.addressDetail.trim()
            if (!addressDetail) {
                result.msg = '文章推广区域地址'
                return result;
            }
            if(!articleInfo.longitude || !articleInfo.latitude){
                result.msg = '根据推广区域地址获取坐标异常'
                return result;
            }
            let articleFileList = articleInfo.articleFileList;
            if(!articleFileList || articleFileList.length <1){
                result.msg = '请选择文章图片'
                return result;
            }
            this.articleInfo.title = title
            this.articleInfo.content = content
            result.code = 200

            return result
        },
        async addArticle(){
            let result = this.checkParam()
            if(result.code!==200){
                this.$message({
                    message: result.msg,
                    type: 'error'
                });
                return
            }

            if(this.addLoading){
                return
            }
            if(this.articleInfo.comboId){
                this.articleInfo.goodsType = 2
            }else{
                this.articleInfo.goodsType = ""
            }
            this.addLoading  = true
            let res =  await addArticleApi(this.articleInfo)
            if(res.code === 200){
                this.$message({
                    message: '添加成功',
                    type: 'success'
                });
                this.dialog = false
                this.$emit('getList')
            }else{
                this.$message({
                    message: res.msg,
                    type: 'error'
                });
            }
            this.addLoading = false
        }

    },
    created() {

    }
}
