<template>
  <div style="min-height: 86vh">
    <div>
      <div style="display:flex;align-items: center;margin-right: 10px;margin-left: 10px">
        <div>
          <i class="ki-outline" style="font-size: 16px" :class="$route.meta.icon"></i>
        </div>
        <el-breadcrumb separator-class="el-icon-arrow-right"
                       style="padding: 10px 5px;font-size: 12px;display: flex;align-items: center">
          <el-breadcrumb-item>{{ this.$route.matched[0].meta.title }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.$route.matched[1].meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="card bg-light shadow-sm" style="background: #fff;margin-top: 10px;border-radius: 15px">
      <div class="card-body collapsible rotate"
           style="background: #fff;border-radius: 15px;display: flex;justify-content: space-between">
        <div style="width: 100%;">
          <div style="display: flex;width: 100%;justify-content: center;">
            <div class="rounded px-4 mb-3"
                 style="border-width: 50px; width: 300px;background: #edf4ff;padding-top: 20px;padding-bottom: 20px;margin-right: 20px;margin-bottom: 0 !important;">
              <div style="display: flex;justify-content:center;align-items: center">
                <img
                    src="https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/%E6%80%BB%E7%B2%89%E4%B8%9D%E6%95%B0.png"
                    alt=""
                    style="width: 51px;height: 43px">
                <div style="margin-left: 20px">
                  <div class="d-flex" style="justify-content: space-between">
                    <span slot="label" class="fs-6" style="display: inline-block;">
                      粉丝数&nbsp;
                    </span>
                  <span slot="label" class="fw-semibold fs-6" style="display: inline-block;color: #ABB3BF">
                    (总数)
                    <el-tooltip content="是指所有关注的，包括首次锁定和锁定别人了但是也关注我的用户。"
                                effect="dark" placement="bottom">
                            <i class="el-icon-question"/>
                          </el-tooltip>
                  </span>
                  </div>
                  <div class="d-flex">
                    <div class="fs-2 fw-bold">
                      {{ total }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded px-4 mb-3"
                 style="border-width: 50px; width: 300px;background: #E8F8FF;padding-top: 20px;padding-bottom: 20px;margin-right: 20px;margin-bottom: 0!important;">
              <div style="display: flex;justify-content:center;align-items: center">
                <img
                    src="https://xysy-saas.oss-cn-hangzhou.aliyuncs.com/lianxianghui/%E4%B8%83%E6%97%A5%E6%96%B0%E5%A2%9E%E7%B2%89%E4%B8%9D%E6%95%B0.png"
                    alt=""
                    style="width: 51px;height: 43px">
                <div style="margin-left: 20px">
                  <div class="d-flex" style="justify-content: space-between">
                    <span slot="label" class="fs-6" style="display: inline-block;">
                      粉丝数&nbsp;
                    </span>
                  <span slot="label" class="fw-semibold fs-6" style="display: inline-block;color: #ABB3BF">
                    (7日净增)
                  </span>
                  </div>
                  <div class="d-flex">
                    <div class="fs-2 fw-bold">
                      {{ countData.sevenDayAdd }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px;margin-top: 10px">
      <div class="tab-content">
        <div class="tab-pane fade show active">
          <div class="d-flex flex-column gap-7 gap-lg-10">
            <div class="card card-flush"
                 style="border-radius:15px">
              <div class="card-body" style="padding-top: 0">
                <div style="margin-top: 14px">
                  <div class="table-responsive">
                    <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                      <thead>
                      <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th class="text-center">
                          头像
                        </th>
                        <th class="text-center">
                          昵称
                        </th>
                        <th class="text-center">
                          手机号
                        </th>

                        <th class="text-center">
                          操作
                        </th>
                      </tr>
                      </thead>
                      <tbody class="fw-semibold text-gray-600">
                      <tr v-for="(item,index) in fansList" :key="index" :class="{
                         old:index%2!==0,
                         even:index%2===0
                      }">
                        <td class="text-center" style="font-size: 10px">
                          <img :src="item.img" style="width: 50px;height: 50px;border-radius: 50%" alt=""/>
                        </td>
                        <td class="text-center">
                          {{ item.name }}
                        </td>
                        <td class="text-center">
                          {{ item.tel }}
                        </td>

                        <td class="text-center">
                          <el-link type="primary" :underline="false" @click="handleClick(item)">私信</el-link>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <div style="display: flex;justify-content: right">
                      <PaginationEl :total="total" :page.sync="params.current" :limit.sync="params.size"
                                    @pagination="getPage"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {getFansCountApi, getFansListApi} from "@/api/lxh/applet/lxh-user";
import PaginationEl from "@/components/PaginationEl/index.vue";

export default {
  name: "fans",
  components: {PaginationEl},
  data() {
    return {
      fansList: [],
      params: {
        page: 1,
        pageSize: 10,
      },
      total: 0,
      countData: {
        sevenDayAdd: 0,
        lockPowder: 0,
        sevenDayLockPowder: 0,

      }
    }
  },
  filters: {},
  methods: {
    async getPage() {
      let {data} = await getFansListApi(this.params)
      this.fansList = data.rows
      this.total = data.total
    },
    async getFansCount() {
      let {data} = await getFansCountApi(this.params)
      this.countData = data
    },
    handleClick(item) {
      this.$router.push({path: "/private-letter",query: {toUuid: item.uuid, toName: item.name}})
    }
  },
  created() {
    this.getPage()
    this.getFansCount()
  },
}
</script>

<style scoped>

</style>