import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import ElementUI, {Message} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Pagination from '@/components/Pagination';
import TitleCard from '@/components/TitleCard';
import ModalDialog from '@/components/ModalDialog';
import GroupTools from '@/components/GroupTools';
import UploadFile from "@/components/UploadFile";
import SvgIcon from "@/components/SvgIcon";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { formatMoney } from '@/utils/formatMoney';
import {echarts} from "echarts";
import CountUp from 'vue-countup-v2';
import { setupClipboardDirective } from './utils/clipboard';
import Clipboard from "clipboard";
import MobileModalBox from "@/components/MobileModalBox/index.vue";
import '@/utils/CopyTextDirective';
import VipPayComponent from "@/components/VipPayComponent/index.vue";
import MaterialComponent from "@/components/MaterialComponent/index.vue";
import SjComponent from "@/components/SjComponent/index";
import IM from './tim/index'

Vue.config.productionTip = false


// 路由前置守卫，显示进度条
// 在每次路由切换之前执行的函数
router.beforeEach((to, from, next) => {
  // 定义允许直接访问的路由列表
  let whitelist=["/login","/register","/vipRegister","/404","/500"]
  // 检查当前路由是否在允许直接访问的路由列表中*6
  if (!whitelist.includes(to.fullPath)) {
    // 若不在列表中，则将当前路由的路径存储到本地存储中
    localStorage.setItem('redirectPath', to.fullPath);
  }
  // 启动进度条动画
  NProgress.start();
  // 继续执行路由切换
  next();
});
// 路由后置守卫，隐藏进度条
router.afterEach(() => {
  NProgress.done(); // 隐藏进度条
});
Vue.filter("format", function(value, retain) {
  return formatMoney(value);
});

// 将 Clipboard.js 挂载到 Vue 原型上
Vue.prototype.$Clipboard = Clipboard;

// 创建一个全局事件总线用于处理复制成功和失败的消息
Vue.prototype.$eventBus = new Vue();

// 在全局监听 Clipboard.js 的 success 和 error 事件，然后使用事件总线发送消息
document.addEventListener('click', (e) => {
  if (e.target.getAttribute('data-clipboard-target')) {
    Vue.prototype.$eventBus.$emit('clipboardSuccess', e);
  } else if (e.target.getAttribute('data-clipboard-text')) {
    Vue.prototype.$eventBus.$emit('clipboardError', e);
  }
});
// 引入 clipboard 模块
setupClipboardDirective();

Vue.prototype.$echarts = echarts;
Vue.component('Pagination', Pagination);
Vue.component('TitleCard', TitleCard);
Vue.component('ModalDialog', ModalDialog);
Vue.component('UploadFile', UploadFile);
Vue.component('GroupTools', GroupTools)
Vue.component('VipPayComponent', VipPayComponent)
Vue.component('SvgIcon', SvgIcon)
Vue.component('CountUp', CountUp)
Vue.component('MobileModalBox', MobileModalBox)
Vue.component('MaterialComponent',MaterialComponent)
Vue.component('SjComponent',SjComponent)
Vue.use(ElementUI);
Vue.use(VueSweetalert2);
Vue.use(IM, {
  SDKAppID: 1600069384, // 用你的即时通信应用的 SDKAppID替换0,
  logLevel: 1 // dev级别用0，release级别用1，SDK 输出关键信息，生产环境时建议使用
})

Vue.prototype.$eventBus.$on('clipboardSuccess', () => {
  Message.success('复制成功');
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
