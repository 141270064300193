import {deleteArticleApi, queryArticlePageApi} from "@/api/lxh/applet/article";
import {getStoreListApi} from "@/api/lxh/store";

export const mixins = {
    components:{
    },
    data(){
        return{
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            params:{
                current:1,
                size:10,
                title:'',
                status:'',
                createTimeStart:'',
                createTimeEnd:'',
                storeId:"",

            },
            createTimeRange:[],
            storeList:[],
            rows:[],
            total:0,
        }
    },

    methods:{
        async getStoreList() {
            let param = {
                current:1,
                size:999
            }
            let {data} = await getStoreListApi(param)
            this.storeList = data.rows;

        },
        handleSizeChange(val) {
            this.params.size = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.params.current = val
            this.getList()
        },
        async getList(){

            let {data}  = await queryArticlePageApi(this.params)
            this.rows = data.rows
            this.total = data.total
        },
        search(){
            if(this.createTimeRange && this.createTimeRange.length>0){
                this.params.createTimeStart = this.createTimeRange[0]
                this.params.createTimeEnd = this.createTimeRange[1]
            }else{
                this.params.createTimeStart = ''
                this.params.createTimeEnd = ''
            }
            this.params.current=1
            this.getList()
        },
        resetSearch(){
            this.params.title = ''
            this.params.storeId = ''
            this.createTimeRange=[]
            this.search()
        },
        createArticle(){
            this.$refs.articleCreate.initDialog()
        },

        editThis(item){
            console.log(item)
            this.$refs.articleEdit.initDialog(item.uuid)
        },
        deleteThis(item){
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async res => {
                if(res === 'confirm'){
                    let res = await deleteArticleApi({uuid:item.uuid})
                    if(res.code === 200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.msg
                        });
                    }
                }
            });
        },
        goToArticleEvaluationManager(articleUuid){
            this.$refs.articleEvaluationManager.init(articleUuid)
        }



    },
    created() {
        this.getStoreList()
        this.getList()
    }
}
