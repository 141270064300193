import Vue from 'vue'
import Vuex from 'vuex'
import getters from "./getters"

Vue.use(Vuex)

import userModules from "./modules/user"
import permissionModules from "./modules/permission"
import webModules from "./modules/web"
import goodsModules from "./modules/goods"
import diaLogModal from "@/store/modules/diaLogModal";
import timModal from "@/store/modules/timModal";

export default new Vuex.Store({
  getters,
  modules: {
    user:userModules,
    permission:permissionModules,
    web:webModules,
    goods:goodsModules,
    diaLog:diaLogModal,
    tim:timModal,
  }
})
