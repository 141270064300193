import {queryTransactionHistoryPageApi} from "@/api/lxh/finance/offline/transactionHistoryApi";
import sd from "silly-datetime";

export const mixins = {
    components: {},
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            rows: [],
            total: 0,
            searchDataRange: [],
            options: [],
            queryParams: {
                current: 1,
                size: 10,
                storeId: "",
                startTime: "",
                endTime: "",
            }
        }
    },
    methods: {
        async getPage() {
            let {data} = await queryTransactionHistoryPageApi(this.queryParams)
            this.rows = data.rows
            this.total = data.total
        },
        async searchOverview(){
            if(this.searchDataRange && this.searchDataRange.length===2){
                this.queryParams.startTime =sd.format(
                    this.searchDataRange[0],
                    "YYYY-MM-DD HH:mm:ss"
                );
                this.queryParams.endTime = sd.format(
                    this.searchDataRange[1],
                    "YYYY-MM-DD HH:mm:ss"
                );
            }else{
                this.queryParams.startTime = ""
                this.queryParams.endTime = ""
            }
            this.queryParams.current = 1
            await this.getPage()
        },
        async resetSearchOverview(){
            this.queryParams =  {
                storeId: "",
                startTime: "",
                endTime: "",
                current: 1,
                size: 10
            }
            this.searchDataRange = []
            await this.getPage()

        }
    },
    created() {
        this.getPage()
    }
}
