import {getStoreListApi} from "@/api/lxh/store";
import {financialOverviewApi, financialOverviewInfoApi} from "@/api/lxh/finance/offline/transactionHistoryApi";
import sd from "silly-datetime";

export const mixins = {
    components: {},
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            options: [],
            value: '',
            storeInfo: null,
            searchDataRange: [],
            queryParams: {
                storeId: "",
                startTime: "",
                endTime: "",
                isOffline: true,
                current: 1,
                size: 10
            },
            headData: {
                verificationQuantity: 0,
                merchantsDeserveIt: 0,
                salesPrice: 0.00,
                reducePrice: 0.00,
                promotionFee: 0.00,
                refundPrice: 0.00,
                userPrice: 0,
                otherServiceChargePrice: 0
            },
            infoData: [],
            infoTotal: 0,
        }
    },
    methods: {
        /**
         * 切换
         */
        storeChange(e) {
            this.storeInfo = JSON.parse(JSON.stringify(this.options.find(item => item.id === e)))
        },
        gotoIncomePage(){
          this.$emit("gotoOtherMenu",2)
        },
        /**
         * 获取门店列表
         */
        async getStoreList(id) {
            let {data} = await getStoreListApi()
            this.options = data.rows;
            if (data.rows.length) {
                if (id) {
                    this.value = id
                    this.storeChange(id)
                } else {
                    this.storeChange()
                }

            }
        },
        async financialOverview() {
            let {data} = await financialOverviewApi(this.queryParams)
            this.headData = data
        },
        async financialOverviewInfo() {
            let {data} = await financialOverviewInfoApi(this.queryParams)
            this.infoData = data.rows
            this.infoTotal = data.total
        },
        async searchOverview(){
            if(this.searchDataRange && this.searchDataRange.length===2){
                this.queryParams.startTime =sd.format(
                    this.searchDataRange[0],
                    "YYYY-MM-DD HH:mm:ss"
                );
                this.queryParams.endTime = sd.format(
                    this.searchDataRange[1],
                    "YYYY-MM-DD HH:mm:ss"
                );
            }else{
                this.queryParams.startTime = ""
                this.queryParams.endTime = ""
            }
            this.queryParams.current = 1
            await this.financialOverviewInfo()
        },
        async resetSearchOverview(){
            this.queryParams =  {
                storeId: "",
                startTime: "",
                endTime: "",
                isOffline: true,
                current: 1,
                size: 10
            }
            this.searchDataRange = []
            await this.financialOverviewInfo()

        }
    },
    created() {
        // this.getStoreList()
        this.financialOverview()
        this.financialOverviewInfo()
    }
}
