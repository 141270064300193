<template>
  <div style="min-height: 80vh">
    <TitleCard title="文章" style="max-width: 97%; margin: 10px auto">
    <template v-slot:subTitle>

    </template>
<!--    <div style="position: absolute; right: 70px">-->
<!--      <a href="javascript:void(0)" class="el-button el-button&#45;&#45;primary mx-2 el-button&#45;&#45;mini" @click="goToArticleEvaluationManager(null)">评论管理</a>-->
<!--    </div>-->
    </TitleCard>


    <div class="card bg-light shadow-sm" style="max-width:97%;background: #fff;margin: 10px auto;border-radius: 15px">
      <div class="card-body"
           style="background: #fff;border-radius: 15px;width: 100%">
        <div>
          <div style="display:flex;justify-content: space-between;align-items: center">
            <div style="display: flex;align-items: center">
              <div class="position-relative w-md-400px me-md-2">
                    <span class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                      <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px"
                           xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><rect height="24" width="24"
                                                                                                x="0" y="0"></rect><path
                            d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                            fill="#000000" fill-rule="nonzero" opacity="0.3"></path><path
                            d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                            fill="#000000" fill-rule="nonzero"></path></g></svg>
                    </span>
                <div class="el-input el-input--prefix">
                  <input v-model="params.title" type="text" autocomplete="off" placeholder="文章标题" class="el-input__inner"
                  >
                  <span class="el-input__prefix">
                        <i class="el-input__icon el-icon-search"></i>
                      </span>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <el-button type="primary" size="small" round @click="search">查询</el-button>
                <el-button size="small" round @click="resetSearch">重置</el-button>
                <a class="btn btn-link" style="margin-left: 10px"
                   id="kt_horizontal_search_advanced_link"
                   data-bs-toggle="collapse"
                   href="#kt_advanced_search_form1"
                >
                  高级查询
                </a>
              </div>
            </div>
            <div>
              <el-button type="primary" size="mini" round @click="createArticle">新增</el-button>
            </div>
          </div>
          <div id="kt_advanced_search_form1" class="collapse">
            <div class="separator separator-dashed mt-9 mb-6"></div>
            <div style="display: flex">
              <div class="col-lg-3">
                <label class="fs-6 form-label fw-bolder text-dark">创建日期范围</label>
                <br/>
                <el-date-picker
                    v-model="createTimeRange"
                    type="datetimerange"
                    :picker-options="pickerOptions"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    style="width: 100%"
                    size="small"
                    align="right">
                </el-date-picker>
              </div>

              <div class="col-lg-3" style="margin-left: 10px">
                <label class="fs-6 form-label fw-bolder text-dark">状态</label>
                <br/>
                <el-select
                    class="w-100"
                    clearable
                    filterable
                    placeholder="请选择"
                    v-model="params.status"
                    size="small"
                >
                  <el-option label="审核中" value="0"></el-option>
                  <el-option label="审核通过" value="1"></el-option>
                  <el-option label="审核不通过" value="2"></el-option>
                </el-select>
              </div>

              <div class="col-lg-3" style="margin-left: 10px">
                <label class="fs-6 form-label fw-bolder text-dark">门店</label>
                <br/>
                <el-select
                    class="w-100"
                    clearable
                    filterable
                    placeholder="请选择"
                    v-model="params.storeId"
                    size="small"
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option label="不关联" value="-1"></el-option>
                  <el-option v-for="(item,index) in storeList"  :value="item.id" :key="index"
                             :label="item.storeName +'('+item.branchName +')' + (item.isSubStore ? '' : ' (主门店)')"
                  ></el-option>

                </el-select>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>

    <el-row style="max-width:97%;margin: 10px auto">

        <div class="flex-column flex-row-fluid gap-7 gap-lg-10" style="border-radius: 15px">
          <div class="tab-content">
            <div
                class="tab-pane fade show active">
              <div class="d-flex flex-column gap-7 gap-lg-10">
                <div class="card card-flush"
                     style="border-radius:15px;min-height: 54vh !important; ">
                  <div class="card-body pt-0">
                    <div class="row" style="padding-left: 0;padding-right: 0;padding-bottom: 0;">
                      <div class="table-responsive" style="display:flex;">
                        <table class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
                          <thead>
                          <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th rowspan="1" colspan="1"
                                style="width: 312.141px;margin-left: 20px">
                              文章标题
                            </th>
                            <th>
                              所属门店
                            </th>

                            <th tabindex="0" rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              可见性
                            </th>
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              状态
                            </th>
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              浏览量
                            </th>
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              点赞量
                            </th>
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              收藏量
                            </th>
                            <th rowspan="1" colspan="1" class="text-center" style="width:100px;">
                              创建时间
                            </th>
                            <th rowspan="1" colspan="1" class="text-center sorting_disabled" style="width:100px;">
                              操作
                            </th>
                          </tr>
                          </thead>
                          <tbody class="fw-semibold text-gray-600">
                          <tr class="old" v-for="(item,index) in rows" :key="index">
                            <td>
                              <div class="d-flex align-items-center" style="margin-left: 20px">
                                <a href="javascript:void(0);" class="symbol symbol-50px">
                                  <img
                                      :src="item.url"
                                      class="symbol-label lozad">
                                </a>
                                <div class="ms-5">
                                  <a href="javascript:void(0);" class="text-gray-800 text-hover-primary fs-5">
                                    {{item.title}}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td class="text-center" :title="item.storeName" style="max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                              {{ item.storeName }}
                            </td>
                            <td class="text-center pe-0">
                              <span>
                                {{item.visibilityLevel===1?'全部可见':'仅自己'}}
                              </span>
                            </td>
                            <td class="text-center pe-0">
                              <el-tag v-if="item.status===0">审核中</el-tag>
                              <el-tag v-else-if="item.status===1" type="success">审核通过</el-tag>
                              <el-tag v-else-if="item.status===2" type="warning">审核不通过</el-tag>
                              <el-tag v-else type="danger">未知</el-tag>
                            </td>
                            <td class="text-center pe-0">
                              0
                            </td>
                            <td class="text-center pe-0">
                              0
                            </td>
                            <td class="text-center pe-0">
                              0
                            </td>
                            <td class="text-center pe-0">
                              {{ item.createTime }}
                            </td>

                            <td class="text-center">
                              <el-link type="primary" @click="editThis(item)">编辑</el-link>

                              <el-link type="danger" @click="deleteThis(item)" style="margin-left: 10px">删除</el-link>
                              <!--      <a href="javascript:void(0)" class="el-button el-button&#45;&#45;primary mx-2 el-button&#45;&#45;mini" @click="goToArticleEvaluationManager(null)">评论管理</a>-->

                              <el-link type="primary" @click="goToArticleEvaluationManager(item.uuid)" style="margin-left: 10px" >评价管理</el-link>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div style="display: flex;justify-content: center">
                      <el-pagination style="display:flex;justify-content:flex-end" @size-change="handleSizeChange"
                                     @current-change="handleCurrentChange" :current-page="params.current" :page-sizes="[10,20,30]"
                                     :page-size="params.size" layout="total, sizes, prev, pager, next, jumper" :total="total"
                                     background>
                      </el-pagination>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
    </el-row>

    <article-create ref="articleCreate" @getList="getList"></article-create>
    <article-edit ref="articleEdit" @getList="getList"></article-edit>
    <article-evaluation-manager ref="articleEvaluationManager" @getList="getList"></article-evaluation-manager>

  </div>
</template>
<script>

import {mixins} from "@/views/Content/article/mixins";
import articleCreate from "@/views/Content/article/create/index.vue"
import articleEdit from "@/views/Content/article/edit/index.vue"
import articleEvaluationManager from "@/views/Content/article/evaluation/index.vue"
import Paginations from "@/components/Paginations/index.vue";
export default {
  components: {Paginations, articleCreate,articleEdit,articleEvaluationManager},
  mixins: [mixins]
}
</script>
