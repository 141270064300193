import tim from '../tim/tim.js';


//登录
export function imLogin(userID,userSig) {
    return new Promise( (resolve, reject) => {
        let promise = tim.txIm.login({userID, userSig});
        promise.then(function(imResponse) {
            console.log(imResponse.data,'登录成功'); // 登录成功
            resolve(imResponse.data)
            if (imResponse.data.repeatLogin === true) {
                // 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
                console.log(imResponse.data.errorInfo);
            }
        }).catch((imError) => {
            console.warn('login error:', imError); // 登录失败的相关信息
        });
    })
}
//退出登录
export function imOutLogin() {
    let promise = tim.txIm.logout();
    promise.then(function(imResponse) {
        console.log(imResponse.data); // 登出成功
        tim.txIm.destroy();//销毁 SDK 实例。SDK 会先 logout，然后断开 WebSocket 长连接，并释放资源
    }).catch(function(imError) {
        console.warn('logout error:', imError);
    });
}
//获取收到的新消息
export function getNewMessage() {
    return new Promise((resolve, reject)=>{
        let onMessageReceived = function(event) {
            console.log(event,'--------------收到的新消息')
            resolve(event.data)
            // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
            // event.name - TIM.EVENT.MESSAGE_RECEIVED
            // event.data - 存储 Message 对象的数组 - [Message]
        };
        tim.txIm.on(tim.txIm.EVENT.MESSAGE_RECEIVED, onMessageReceived);
    })
}
//发送文本消息
export function setTextMessage(messageTetx,toUserId) {
    return new Promise( (resolve, reject) => {
        let message = tim.txIm.createTextMessage({
            to: toUserId,//要发送的用户的userid
            conversationType: tim.txIm.TYPES.CONV_C2C,
            payload: {
                text: messageTetx //发送内容
            },
            needReadReceipt: true
        });
        let promise = tim.txIm.sendMessage(message);
        promise.then(function(imResponse) {
            console.log(imResponse,'发送成功');
            resolve(imResponse.data.message)
        }).catch(function(imError) {
            console.warn('sendMessage error:', imError);
        });
    })
}
//获取会话列表
export function getAllConversationList(){
    return new Promise((resolve, reject)=>{
        tim.txIm.getConversationList().then(imRes=>{
            console.log(imRes,'获取会话列表')
            const conversationList = imRes.data.conversationList; // 全量的会话列表，用该列表覆盖原有的会话列表
            const isSyncCompleted = imRes.data.isSyncCompleted; // 从云端同步会话列表是否完成
            if(isSyncCompleted){
                resolve({conversationList, isSyncCompleted})
            }
        })
    })
}
//获取聊天记录
export function getMessageList(userId,nextReqMessageID) {
    return new Promise( (resolve, reject) => {
        tim.txIm.setMessageRead({
            conversationID: `C2C${userId}`
        });
        if(nextReqMessageID) {
            // 下拉查看更多消息
            let promise = tim.txIm.getMessageList({conversationID: `C2C${userId}`, nextReqMessageID});
            promise.then(function(imResponse) {
                const messageList = imResponse.data.messageList; // 消息列表。
                const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
                const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
                console.log(messageList,'消息列表')
                console.log(nextReqMessageID,'用于续拉，分页续拉时需传入该字段。')
                console.log(isCompleted,'表示是否已经拉完所有消息')
                resolve({messageList,nextReqMessageID,isCompleted})
            });
        }else {
            // 打开某个会话时，第一次拉取消息列表，注意！第一次拉取时不要传入 nextReqMessageID
            let promise = tim.txIm.getMessageList({conversationID: `C2C${userId}`});
            promise.then(function(imResponse) {
                const messageList = imResponse.data.messageList; // 消息列表。
                const nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
                const isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
                console.log(messageList,'消息列表')
                console.log(nextReqMessageID,'用于续拉，分页续拉时需传入该字段。')
                console.log(isCompleted,'表示是否已经拉完所有消息')
                resolve({messageList,nextReqMessageID,isCompleted})
            });
        }
    })
}
