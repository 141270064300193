import tim from './tim'
import TIMUploadPlugin from "tim-upload-plugin";
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
import TIM from "tim-js-sdk"; // 使用前请将 IM SDK 升级到v2.24.0或更高版本
export default {
    install(app, timOptions){
        // 创建及时通讯实例
        tim.create(timOptions)
        // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
        tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin})
        // 注册腾讯云即时通信 IM 本地审核插件
        tim.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin})
        // 添加腾讯tim服务实例到Vue实例
        tim.txIm.on(TIM.EVENT.SDK_READY,()=>{})
        app.prototype.$tim = tim.txIm
    }
}
