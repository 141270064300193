<!-- 组件模板内容 -->
<template>
  <div style="min-height: 100vh;">
    <!--顶部标题-->
    <TitleCard style="margin: 10px auto; max-width: 97%" title="财务管理">
      <template v-slot:subTitle>
        <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
          <li class="breadcrumb-item text-muted">Finance Management</li>
        </ul>
      </template>
    </TitleCard>
    <div id="kt_content_container" class="container" style="
        max-width: 98%;
        flex-direction: row !important;
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
      ">
      <!-- 左侧菜单 -->
      <div class="flex-column flex-lg-row-auto w-100 w-lg-250px w-xxl-325px mb-8 mb-lg-0 me-lg-9 me-5">
        <div class="card">

          <div class="card-body" style="
              padding: 1.2rem 1rem;
              width: 300px;
              box-sizing: content-box;
              padding: 1rem !important;
            ">
            <el-menu ref = "menu" class="el-menu-vertical-demo" :default-active="index" @select="selectMenu">
              <label class="fs-6 form-label fw-bolder text-dark mb-lg-0 me-lg-9">服务菜单</label>
              <el-menu-item index="1">
                <span slot="title">财务总览</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title">收益流水</span>
              </el-menu-item>
              <el-menu-item index="3">
                <span slot="title">提现流水</span>
              </el-menu-item>
              <el-menu-item index="4">
                <span slot="title">收款账户</span>
              </el-menu-item>
              <el-menu-item index="5">
                <span slot="title">发票抬头</span>
              </el-menu-item>
            </el-menu>
            <br/>
            <el-divider content-position="center"></el-divider>
          </div>
        </div>
      </div>
      <!-- 右侧内容 -->

      <div class="card-body">
        <div class="table-responsive">
          <overview v-if="index == 1" @gotoOtherMenu="gotoOtherMenu"></overview>
          <income v-if="index == 2"></income>
          <withdraw v-if="index == 3"></withdraw>
          <CardList v-if="index == 4"></CardList>
          <bill v-if="index == 5"></bill>
        </div>
      </div>
    </div>
    <ModalDialog ref="RechargeCreate" title="充值" width="50%">
      <RechargeCreate ref="formRechargeCreate" @submit="clickRechargeCreate">
        <template slot="button">
          <el-form-item>
            <el-button type="primary" @click="$refs.formRechargeCreate.submit()">提交</el-button>
            <el-button @click="clickCancelRechargeCreate">取消</el-button>
          </el-form-item>
        </template>
      </RechargeCreate>
    </ModalDialog>
    <ModalDialog ref="CashOutModalDialog" title="提现" width="90%">
      <div class="table-responsive">
        <h3>
          已经勾选{{ idArr.length }}个订单,共计{{ sumXyygBoMoney | format }}
        </h3>
        <table id="kt_ecommerce_products_table"
               class="table align-middle table-row-dashed table-hover fs-6 gy-5 dataTable no-footer">
          <thead>
          <tr class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
            <th aria-label="" class="w-10px pe-2 sorting_disabled" colspan="1" rowspan="1" style="width: 29.8906px">
              <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                <input v-model="checkboxInput" class="form-check-input" data-kt-check="true"
                       data-kt-check-target="#kt_ecommerce_products_table .form-check-input" type="checkbox"
                       value="TRUE" @change="checkboxInputEvent"/>
              </div>
            </th>
            <th>订单号</th>
            <th>订单状态</th>
            <th>商品标题</th>
            <th>商品数量</th>
            <th>商品结算价</th>
            <th>下单时间</th>
          </tr>
          </thead>
          <tbody class="fw-semibold text-gray-600">
          <tr v-for="(item, index) in rows" :key="index" :class="{ old: index % 2 == 0, even: index % 2 != 0 }">
            <!-- 复选按钮 -->
            <td>
              <div class="form-check form-check-sm form-check-custom form-check-solid">
                <input :checked="idArr.includes(item.orderNo)" class="form-check-input" type="checkbox" value="1"
                       @click="addIdArr(item.orderNo)"/>
              </div>
            </td>
            <td>{{ item.orderNo }}</td>
            <td>{{ item.orderTypeStr }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.num }}</td>
            <td>{{ item.xyygBoMoney | format }}</td>
            <td>{{ item.crTime }}</td>
          </tr>
          </tbody>
        </table>
        <Pagination :total-items="totalCount" @page-change="pageChange"></Pagination>
      </div>
      <div style="display: flex; justify-content: right; margin-right: 20px">
        <!--  -->
        <el-button type="primary" @click="next">下一步<i
            class="el-icon-arrow-right el-icon--right"></i></el-button>
      </div>
    </ModalDialog>
    <ModalDialog ref="nextCashOutModalDialog" title="提现" width="40%">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="提现金额(元)">
          <el-input :readonly="true" :value="form.isSelectAll==1 ? myCashOut.totalMoney : sumXyygBoMoney"
                    placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="订单笔数(笔)">
          <el-input :readonly="true" :value="form.isSelectAll==1 ? totalCount : idArr.length"
                    placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item label="结算日期">
          <el-input :readonly="true" :value="sdF(input.time)" placeholder="请输入内容"></el-input>
        </el-form-item>
        <el-form-item :required="true" label="提现方式">
          <el-select v-model="form.type" placeholder="提现方式" @change="changeType">
            <el-option :value="1" label="微信"></el-option>
            <el-option :value="2" label="支付宝"></el-option>
            <el-option :value="3" label="银行卡"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type" label="提现订单">
          <el-switch v-model="form.isSelectAll" :active-value="1" :inactive-value="2" active-text="全部提现"
                     inactive-text="勾选订单"
                     @change="changeType">
          </el-switch>
        </el-form-item>
        <div v-if="form.type">
          <el-form-item label="提现账户">
            <img v-if="form.type == 1" :src="input.info.content" alt="" width="130px"/>
            <el-input v-else v-model="input.info.content" :readonly="true" placeholder="请输入内容"></el-input>
          </el-form-item>
          <el-form-item label="收款人姓名">
            <el-input v-model="input.info.name" :readonly="true" placeholder="请输入内容"></el-input>
          </el-form-item>

          <!-- <el-form-item label="开户行" v-if="form.type == 3">
            <el-input :value="input.info.bankName" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="增值税抬头" v-if="form.type == 3">
            <el-input :value="input.info.isBank ? '对公账户' : '个人账户'" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item> -->

          <!-- <el-form-item label="增值税抬头">
            <el-input v-model="input.invoice.taxHeader" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="增值税税号">
            <el-input v-model="input.invoice.taxID" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="增值税开户行及账号">
            <el-input v-model="input.invoice.openingBank" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="增值税开户行地址">
            <el-input v-model="input.invoice.address" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="收票地址">
            <el-input v-model="input.invoice.shippingAddress" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="收票邮箱">
            <el-input v-model="input.invoice.mailbox" placeholder="请输入内容" :readonly="true"></el-input>
          </el-form-item> -->
        </div>
      </el-form>
      <div v-if="form.type">
        <el-table :data="fpList" style="width: 100%">
          <el-table-column label="增值税抬头" prop="companyName">
            <template slot-scope="scope">
              <el-popover v-if="scope.row.money != 0" placement="top-start" trigger="hover">
                <p class="fw-bold">发票信息</p>
                <p>发票抬头: {{ scope.row.companyName }}</p>
                <p>发票税号: {{ scope.row.taxID }}</p>
                <p>开户行及账号: {{ scope.row.openingBankAndAccountNumber }}</p>
                <p>开户行地址: {{ scope.row.bankAddress }}</p>
                <p>收票地址: {{ scope.row.ticketReceivingAddress }}</p>
                <p>收票邮箱: {{ scope.row.ticketReceivingEmail }}</p>
                <div slot="reference" class="name-wrapper">
                  {{ scope.row.companyName }} <i class="el-icon-question"></i>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="订单数" prop="ordersNos">
            <template slot-scope="scope">
              {{ scope.row.ordersNos.length }}
            </template>
          </el-table-column>
          <el-table-column label="金额" prop="money">
            <template slot-scope="scope">
              {{ scope.row.money | format }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div style="display: flex; justify-content: right; margin-right: 20px">
        <el-button type="primary" @click="$refs.nextCashOutModalDialog.close()"><i
            class="el-icon-arrow-left el-icon--left"></i>上一步
        </el-button>
        <el-button type="primary" @click="submutSave">提交</el-button>
      </div>
    </ModalDialog>
    <ModalDialog ref="ApplyForInvoicingModalDialog" title="申请开票" width="80%">
      <ApplyForInvoicing ref="ApplyForInvoicing"
                         @close="$refs.ApplyForInvoicingModalDialog.close()"></ApplyForInvoicing>
    </ModalDialog>
  </div>
</template>

<script>
import CardList from "./components/card/CardList.vue";
import MerchantIdList from "./components/MerchantId/MerchantIdList.vue";
import InvoiceList from "./components/Invoice/InvoiceList.vue";
import CashOutList from "./components/CashOut/CashOutList.vue";
import RechargeRecordList from "./components/RechargeRecord/RechargeRecordList.vue";
import InvoiceApplicationList from "@/views/Finance/components/InvoiceApplication/InvoiceApplicationList.vue";
import {cashOutApplicationApi, getMyCashOut} from "@/api/sjCashOutApi";
import {formatMoney} from "@/utils/formatMoney";
import InvoiceApplicationDetails from "@/views/Finance/components/InvoiceApplication/InvoiceApplicationDetails.vue";
import RechargeCreate from "@/views/Finance/components/RechargeRecord/RechargeCreate.vue";
import {getMyCashOutOrdersApi} from "@/api/orderApi";
import sd from "silly-datetime";
import {getMyCardInfoByTypeApi} from "@/api/cardApi";
import ApplyForInvoicing from "./components/ApplyForInvoicing/index.vue"
import {getInvoiceInfoByOrdersApi} from "@/api/invoiceApi";
import overview from "@/views/Finance/components/overview/index.vue";
import income from "@/views/Finance/components/income/index.vue";
import withdraw from "@/views/Finance/components/withdraw/index.vue";
import bill from "@/views/Finance/components/bill/index.vue";

export default {
  name: "",
  components: {
    RechargeCreate,
    InvoiceApplicationDetails,
    InvoiceApplicationList,
    CashOutList,
    InvoiceList,
    MerchantIdList,
    CardList,
    RechargeRecordList,
    ApplyForInvoicing,
    overview,
    income,
    withdraw,
    bill
  },
  filter: {},
  // 组件的props定义,用于子组件接收父组件传值
  props: {},
  // 组件的data属性
  data() {
    return {
      input: {
        money: "",
        count: 0,
        time: new Date(),
        invoice: {
          taxHeader: "",
          taxID: "",
          openingBank: "",
          address: "",
          shippingAddress: "",
          mailbox: "",
        },
        info: {
          type: "",
          content: "",
          name: "",
          crTime: "",
          bankName: "",
          isBank: "",
        },
      },
      form: {
        type: "",
        isSelectAll: 1
      },
      checkboxInput: false,
      idArr: [],
      index: 1,
      myCashOut: {
        money: "",
      },
      rows: [],
      pageObj: {
        page: 1,
        size: 10,
      },
      totalCount: 999,
      tempArr: [],
      fpList: [],
    };
  },
  computed: {
    sumXyygBoMoney() {
      let sum = 0;
      this.tempArr.forEach((e) => {
        if (this.idArr.includes(e.orderNo)) {
          sum += e.xyygBoMoney * 100;
        }
      });
      return sum / 100;
    },
  },
  // 组件的方法
  methods: {
    next() {
      this.form = {
        type: "",
        isSelectAll: 2
      }
      this.$refs.nextCashOutModalDialog.open();
      this.form.type = ""
    },
    openApplyForInvoicingModalDialog() {
      this.$refs.ApplyForInvoicingModalDialog.open()
      this.$refs.ApplyForInvoicing.getList()
    },
    async changeType() {
      let {data} = await getMyCardInfoByTypeApi({
        type: this.form.type,
      });
      let {data: res} = await getInvoiceInfoByOrdersApi({
        orderIds: this.idArr,
        isAll: this.form.isSelectAll
      });
      this.fpList = res.filter(e => {
        return e.money != 0
      })
      this.input.info = data.info || "";
      this.input.invoice = data.invoice || "";
    },
    sdF(date) {
      return sd.format(new Date(), "YYYY-MM-DD HH:mm:ss");
    },
    async submutSave() {
      if (!this.form.type) {
        this.$message({
          type: "warning",
          message: "请选择提现方式",
        });
        return;
      }
      let {data} = await cashOutApplicationApi({
        orderIds: this.form.isSelectAll==1 ? [] : this.idArr,
        isSelectAll: this.form.isSelectAll,
        money: this.form.isSelectAll==1 ? this.myCashOut.totalMoney : this.sumXyygBoMoney,
        type: this.form.type,
        orderNum: this.form.isSelectAll==1 ? this.totalCount : this.idArr.length,
      });
      this.$refs.nextCashOutModalDialog.close()
      this.$refs.CashOutModalDialog.close()
      this.$message({
        type: "success",
        message: "提现申请成功，请等待管理员审核。",
      });

    },
    checkboxInputEvent() {
      if (this.checkboxInput) {
        this.rows.forEach((e) => {
          if (!this.idArr.includes(e.orderNo)) {
            this.idArr.push(e.orderNo);
          }
        });
      } else {
        this.rows.forEach((e) => {
          let index = this.idArr.indexOf(e.orderNo);
          if (index !== -1) {
            this.idArr.splice(index, 1);
          }
        });
      }
    },
    addIdArr(id) {
      if (this.idArr.includes(id)) {
        let index = this.idArr.indexOf(id);
        if (index !== -1) {
          this.idArr.splice(index, 1);
        }
      } else {
        this.idArr.push(id);
      }
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.orderNo)) {
          a = false;
        }
      });
      this.checkboxInput = a;
    },
    selectMenu(index) {
      this.index = index;
    },
    gotoOtherMenu(index){
      this.index = index
    },
    pageChange(currentPage, pageSize) {
      this.pageObj.page = currentPage;
      this.pageObj.size = pageSize;
      this.openCashOut();
    },
    async getMyCashOut() {
      let {data} = await getMyCashOut();
      this.myCashOut = data.info;
    },
    async openCashOut() {
      this.idArr = [];
      this.$refs.CashOutModalDialog.open();
      let {data} = await getMyCashOutOrdersApi(this.pageObj);
      this.rows = data.rows;
      this.totalCount = data.total;
      let a = true;
      this.rows.forEach((e) => {
        if (!this.idArr.includes(e.orderNo)) {
          a = false;
        }
      });
      this.tempArr.push(...this.rows);

      this.tempArr = this.tempArr.filter((item, index, self) => {
        return (
            index ===
            self.findIndex(
                (t) => t.orderNo === item.orderNo // 根据对象的id属性来判断是否重复
            )
        );
      });
      this.checkboxInput = a;
    },
    clickRechargeCreate() {
      this.$refs.formRechargeCreate.clearData();
      this.$refs.RechargeCreate.close();
    },
    clickCancelRechargeCreate() {
      this.$refs.formRechargeCreate.clearData();
      this.$refs.RechargeCreate.close();
    },
  },
  filters: {
    format(num) {
      return formatMoney(num);
    },
  },
  // 在created钩子中执行的操作
  created() {
    this.getMyCashOut();
  },
};
</script>

<!-- 组件的样式 -->
<style scoped>
.el-divider {
  margin: 0;
}

.is-active {
  background: #ecf5ff;
}

.el-menu {
  border-right: none;
}
</style>